import React from 'react';

function New() {
    return (
      <div className="new-container">
          <div className="new-lable">
            <div className="new-text">
                New
            </div>
          </div>
      </div>
      
    );
  }
  
  export default New;